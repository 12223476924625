import './jquery-plugins'
import './accesibility'
import './navigation'
import './research-thrusts'
import './primary-navigation'
import './animations'

import Menu from './header'

var menus = new Menu()
