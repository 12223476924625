class Header {
    constructor() {
        this.burger = document.querySelector('.burger')
        this.nav = document.querySelector('#mobile-menu')
        console.log(this.burger, this.nav)
        this.events()

    }

    events() {
        this.burger.addEventListener('click', (e) => {
            this.toggleBurger(e)
        })

        window.addEventListener('resize', (e) => {
            this.handleWindowResize()
        })
    }

    toggleBurger(e) {
        jQuery(this.nav).slideToggle();
        jQuery(this.burger).toggleClass('open');
    }

    handleWindowResize() {
        if (window.innerWidth > 768) {
            jQuery(this.nav).slideUp()
            jQuery(this.burger).removeClass('open');
        } else {
            // jQuery(this.nav).slideUp()
            // jQuery(this.burger).removeClass('open');
        }
    }


}


export default Header