let submenus = document.querySelectorAll("ul#primary-menu ul.sub-menu")
submenus.forEach((menu, index) => {
    // Hide menu
    menu.style['z-index'] = '-99'
    // Store current max-height
    let max = menu.style['max-height']
    // Placeholder to show full menu, but hidden due to z-index
    menu.style['max-height'] = 'initial'
    // Create style element
    let style = document.createElement('style');
    // Add style for particular node
    style.innerText = 
    `
    ul#primary-menu>li:nth-of-type(${index + 1})>ul.sub-menu.active,
    ul#primary-menu>li:nth-of-type(${index + 1}):hover>ul.sub-menu { 
      max-height:${menu.offsetHeight}px !important;
    }`
    // Append stylesheet to head
    document.head.append(style)
    // Reset old max-height and z-index
    menu.style['max-height'] = max
    menu.style['z-index'] = '1'
})

// Gather top level menu links
const top_level_li = Array(...document.querySelectorAll('ul#primary-menu > li'))
// Adding watchers for tabbing, adds active class to submenu when li has tabbed child, removes when not
top_level_li.forEach(elm=> {
  elm.addEventListener('focusin', (event) => {
  
    if (!elm.contains(event.relatedTarget)) {
      console.log("entered!")
      elm.querySelector('.sub-menu').classList.add('active')
    }
  })
  
  elm.addEventListener('focusout', (event) => {
    
    if (!elm.contains(event.relatedTarget)) {
      console.log("Left!");
      elm.querySelector('.sub-menu').classList.remove('active')
    }
  })
})
