import gsap, { Sine } from "gsap";

// Declaring intersection observer to watch projects
const project_observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        console.log('intersecting: ', entry)
        if (entry.isIntersecting) {
            // Could use .toggle(), but this is more explicit
            entry.target.classList.add('on-screen')
        } else {
            entry.target.classList.remove('on-screen')
        }
    });
}, {
    rootMargin: "-10% 0px -10% 0px",
});

document.querySelectorAll('.content .thrust-projects-container .thrust-project .content').forEach(el => {
    project_observer.observe(el);
})

const callout_observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        // content = entry.target.querySelector('.content');
        console.log('intersecting: ', entry.target)
        if (entry.isIntersecting) {
            // Could use .toggle(), but this is more explicit
            entry.target.querySelector('.content').classList.add('on-screen')
        } else {
            entry.target.querySelector('.content').classList.remove('on-screen')
        }
    });
}, {
    rootMargin: "-30% 0px -50% 0px",
});

document.querySelectorAll('.content .callout-fullbody-container').forEach(el => {
    callout_observer.observe(el);
})

const research_thrusts_observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        // content = entry.target.querySelector('.content');
        console.log('intersecting: ', entry.target)
        if (entry.isIntersecting && !entry.target.classList.contains('on-screen')) {
            // Could use .toggle(), but this is more explicit
            entry.target.classList.add('on-screen')
        }
    });
}, {
    rootMargin: "-30% 0px -20% 0px",
});

document.querySelectorAll('.content .research-container .thrust').forEach(el => {
    research_thrusts_observer.observe(el);
})

const about_block_observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        // content = entry.target.querySelector('.content');
        console.log('intersecting: ', entry.target)
        if (entry.isIntersecting && !entry.target.classList.contains('on-screen')) {
            // Could use .toggle(), but this is more explicit

            if (entry.target.querySelector('.left-side')) {
                entry.target.querySelector('.left-side').classList.add('on-screen');
                entry.target.querySelector('.right-side').classList.add('on-screen');
            } else {
                entry.target.querySelector('.inner-about-container').classList.add('on-screen');
            }
            
        }
    });
}, {
    rootMargin: "-30% 0px -30% 0px",
});

document.querySelectorAll('article.page .about-container').forEach(el => {
    about_block_observer.observe(el);
})


jQuery("#mobile-menu .menu-item-has-children").each(function () {
    jQuery(this).on('click', function () {
        jQuery(this).children('.sub-menu').toggleClass('active')
    })
})

// Helper function to generate random number, with direction as a default parameter
function random(min, max) {
    const delta = max - min;
    // Direction defaults to 1 unless provided
    return (direction = 1) => (min + delta * Math.random()) * direction;
}

// Setting bounds for different random number generators
const randomX = random(0, 15);
const randomY = random(0, 15);
const randomDelay = random(0, 1);
const randomTime = random(1.5, 4);

// Move function in x-direction
function moveX(target, direction) {
    gsap.to(target, randomTime(), {
        x: randomX(direction),
        ease: Sine.easeInOut,
        delay: randomDelay(),
        onComplete: moveX,
        // Same target, but swap the direction
        onCompleteParams: [target, direction * -1]
    });
}

// Move function in y-direction
function moveY(target, direction) {
    gsap.to(target, randomTime(), {
        y: randomY(direction),
        ease: Sine.easeInOut,
        delay: randomDelay(),
        onComplete: moveY,
        // Same target, but swap the direction
        onCompleteParams: [target, direction * -1]
    });
}

// Initiate animations.
if (document.querySelector(".page-header.front-page")) {
    // Grabbing objects to animate
    const layer_2 = document.querySelector("object.layer-2")
    const layer_3 = document.querySelector("object.layer-3")
    const layer_4 = document.querySelector("object.layer-4")
    // Animate!
    moveX(layer_2, 1)
    moveX(layer_3, -1)
    moveX(layer_4, 1)
    moveY(layer_2, -1)
    moveY(layer_3, 1)
    moveY(layer_4, 1)
}