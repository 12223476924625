// Thickness of lines made in pixels represented as a number.
import $ from "jquery";

// Thickness of line drawn in pixels
const THICKNESS = 3;
// Color of line drawn, set to root custom property
const COLOR = 'var(--dark_blue)';

function draw_arrows() {
    // Gather all instances of thrust block
    let thrust_blocks = document.querySelectorAll('.content .research-thrusts-background');
    // For each block
    thrust_blocks.forEach(block => compute(block, ".icon object", "thrust"))

    // Gather all instances of current projects on thrust page
    let thrust_projects = document.querySelectorAll('.content .thrust-projects-container')

    // For each block
    thrust_projects.forEach(project => compute(project, ".thrust-project .circle", "project", ".thrust-project"))

    // Shared compute instructions
    function compute(block, selector, name, custom_container) {
        // Gather icons
        let icons = block.querySelectorAll(selector);
        // Gathering custom containers for lines
        let custom_containers = custom_container ? block.querySelectorAll(custom_container) : null;
        // Declare variables
        let positions = [];
        let i = 0;
        // Get position of each icon
        for (i; i < icons.length; i++) {
            positions.push(getPositions(icons[i]))
        }
        // Create line/div and append to the end of thrust block
        for (i = 0; i < positions.length - 1; i++) {
            let measurements = drawLine(positions[i], positions[i + 1])
            let htmlLine =
                `<div class="${name}-line ${name}-num-${i}" style='padding:0px;
                margin: 0px; 
                height: ${THICKNESS}px; 
                background-color: ${COLOR}; 
                line-height:1px; 
                position:absolute;
                z-index: 1; 
                left: ${measurements.dx}px; 
                top: ${measurements.dy}px; 
                width:${measurements.len}px; 
                -moz-transform:rotate(${measurements.angle}deg); 
                -webkit-transform:rotate(${measurements.angle}deg); 
                -o-transform:rotate(${measurements.angle}deg); 
                -ms-transform:rotate(${measurements.angle}deg); 
                transform:rotate(${measurements.angle}deg);' 
                />`;

            
            if (custom_containers) {
                $(custom_containers[i]).append(htmlLine)
            } else {
                $(block).append(htmlLine)
            }
        }
    }
}

function redraw_arrows() {
    // Gather all instances of thrust block
    let thrust_blocks = document.querySelectorAll('.content .research-thrusts-background');
    // For each block
    thrust_blocks.forEach(block => compute(block, ".icon object", "thrust"))

    // Gather all instances of current projects on thrust page
    let thrust_projects = document.querySelectorAll('.content .thrust-projects-container')
    // For each block
    thrust_projects.forEach(project => compute(project, ".thrust-project .circle", "project"))

    // Shared compute instructions
    function compute(block, selector, name) {
        // Gather icons
        let icons = block.querySelectorAll(selector);
        // Declare variables
        let positions = [];
        let i = 0;
        // Get position of each icon
        for (i; i < icons.length; i++) {
            positions.push(getPositions(icons[i]))
        }
        // Gather all created lines
        let lines = block.querySelectorAll(`.${name}-line`)
        // For all new positions
        for (i = 0; i < positions.length - 1; i++) {
            // Gather measurements
            let measurements = drawLine(positions[i], positions[i + 1])
            // Alter measurements on existing lines. More performant than creating new lines and deleting the old ones.
            lines[i].style.height = `${THICKNESS}px`
            lines[i].style.left = `${measurements.dx}px`
            lines[i].style.top = `${measurements.dy}px`
            lines[i].style.width = `${measurements.len}px`
            lines[i].style['transform'] = `rotate(${measurements.angle}deg)`
            lines[i].style['-moz-transform'] = `rotate(${measurements.angle}deg)`
            lines[i].style['-o-transform'] = `rotate(${measurements.angle}deg)`
            lines[i].style['-ms-transform'] = `rotate(${measurements.angle}deg)`
            lines[i].style['-webkit-transform'] = `rotate(${measurements.angle}deg)`

        }
    }
}


/**
 * Gathers positional information from a given node
 * 
 * @argument icon - Node
 * 
 */
function getPositions(icon) {
    let rect = icon.getBoundingClientRect();
    let j = $(icon).position()

    let obj = {
        left: j.left,
        top: j.top,
        width: rect.width || icon.offsetWidth,
        height: rect.height || icon.offsetHeight
    }

    return obj
}

/**
 * Calculates values for the line that is to be created. Uses Pythagorean Theorem <3
 * 
 * @param {*} node1 First Node
 * @param {*} node2 Second Node
 * @returns width, height, length, and angle of connecting line.
 */
function drawLine(node1, node2) {
    // Calculating middle points of node1
    let x1 = node1.left + (node1.width / 2)
    let y1 = node1.top + (node1.height / 2)
    // Calculating middle points of node2
    let x2 = node2.left + (node2.width / 2)
    let y2 = node2.top + (node2.height / 2)
    // Calculating length of line
    let len = Math.sqrt(((x2 - x1) * (x2 - x1)) + ((y2 - y1) * (y2 - y1)))
    // Calculating left positioning of line
    let dx = ((x1 + x2) / 2) - (len / 2)
    // Calculating right positioning of line
    let dy = ((y1 + y2) / 2) - (THICKNESS / 2)
    // Calculating angle to rotate div to intersect middle points of both nodes
    let angle = Math.atan2((y1 - y2), (x1 - x2)) * (180 / Math.PI);

    return {
        'dx': dx,
        'dy': dy,
        'len': len,
        'angle': angle
    }
}

// Draw on load
$(window).on('load', draw_arrows)
// Updated on screen resize. Timer will reset if the method is called again before 50ms
$(window).on('resize', redraw_arrows);
